<template lang='pug'>
    v-container.skeleton-container(style='margin-top: 3%; margin-left: 1%; padding-right: 24%;')
        v-skeleton-loader.mb-2(type='text' width='20%')
        v-skeleton-loader.mb-1(type='text')
        v-skeleton-loader.mb-4(type='text')

        v-row.mb-4
            v-col.pl-0(cols='3' v-for='n in 4' :key="'row1-'+n")
                v-skeleton-loader(type='image')
        v-skeleton-loader.mb-2(type='text' width='30%')

        v-skeleton-loader.mb-2(type='text' width='20%')
        v-skeleton-loader.mb-1(type='text')
        v-skeleton-loader.mb-4(type='text')

        v-row
            v-col.pl-0(cols='3' v-for='n in 4' :key="'row2-'+n")
                v-skeleton-loader(type='image')

</template>
<script>
export default {
    name: "portalLoadingSkeleton",
    components: {
    },
    data() {
        return{

            
        }
    },
}
</script>
<style scoped lang="css">

</style>