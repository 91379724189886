<template lang="pug">
    v-container.detail-user-input.px-0.ml-4
        v-row.justify-center(style='')
            v-col.d-flex.align-center.input-border.py-0.mb-5(cols='12' style='background-color:#fff; border: 6px solid #F4F4F4;' @mouseleave="ishover = false" @mouseover="ishover = true" :class="{'has-detail-text': message.length > 0 || ishover}")
                v-menu(offset-y="" top persistent)
                    template(v-slot:activator="{ on, attrs }")
                        v-btn(class="kw-box d-flex justify-start align-center custom-btn" color="transparent" icon v-bind="attrs" v-on="on")
                            v-avatar(class="kw-button avatar-effect" icon size="24")
                                v-img(contain height="21.5px" src="@/assets/images/knowledge_select.svg" width="21px")
                            v-img(class="kw-button-tri" contain height="" src="@/assets/images/Triangle_Down.svg" style="height: 5px; width: 10px;")
                    v-card()
                        v-list.list-item-scroll(flat)
                            v-list-item-group(multiple)
                                v-list-item(v-for="item in knowledgeList" :key="item.id" @click.stop )
                                    template(v-slot:default="{ active }" )
                                        v-list-item-action.mr-2
                                            v-checkbox.ma-0(v-model='selectedKnowledgeLibs' :value='item' :value-comparator="(a, b) => a.id === b.id")
                                        v-list-item-content(style="display: inline;")
                                            v-avatar(size='20' max-height='20' :color='item.color'  left class="mr-2")
                                                span(style='padding-bottom:1px;')  {{item.name.slice(0,1)}}
                                            span {{item.name}}
                v-text-field.portal-solo(v-model="message" hide-details persistent-placeholder dense solo placeholder="Ask follow up" underlined class="input-field-upper  custom-height h5-en-regular ma-0 pa-0 d-flex align-end  custom-text-field v-input__control" @keydown.enter='queryQuestion')
                v-btn(:disabled="!hasText || generating" class="send-button  d-flex justify-end align-center" icon @click="queryQuestion" )
                    v-img(:src="sendButtonIcon" contain height="32" width="32")
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        knowledgeList: {
            type: Array,
            require:true,
        },
        generating: {
            type: Boolean,
            require:true,
        },
    },
    components: {

    },
    async created(){
    },
    data() {
        return{
            message: "",
            sendIconA: require("@/assets/images/send.svg"), // A 圖片 (有文字時)
            sendIconB: require("@/assets/images/send_no_tex.svg"), // B 圖片 (無文字時)
            selectedKnowledgeLibs: this.knowledgeList,
            ishover: false,
        }
    },
    methods: {
        queryQuestion(){
            if (this.hasText) {
                let data = {
                    "question_title": this.message,
                    "knowledges": this.selectedKnowledgeLibs
                }
                console.log('22222',data)
                this.$emit("queryQuestion", data);
                this.message = ""; // 清空輸入框
            }
        }
    },
    computed: {
        hasText() {
            return this.message.trim().length > 0;
        },
        sendButtonIcon() {
            return this.hasText ? this.sendIconA : this.sendIconB;
        },
    },
    watch: {

    }
})
</script>

<style lang="scss" scoped>
@import "@/style/portal/typography.scss";
@import "@/style/portal/component.scss";

/* 調整 v-input__control 的高度 */
.custom-btn:active .avatar-effect,
.custom-btn:focus .avatar-effect {
  background-color: rgba(0, 0, 0, 0.2); /* 點擊時的灰色背景 */
  border-radius: 50%; /* 確保背景是圓形的 */
  transition: background-color 0.2s ease-in-out;
}

.v-input__control .v-input__underlined {
  transition: none !important; /* 禁用動畫 */
}

.v-menu__content {
  box-shadow: 0px 0px 8px #9999994D;
}

// detail input
.detail-user-input{
    position: fixed;
    z-index: 20;
    bottom: 0;
    // background: white;
}




</style>