<template lang="pug">
    v-card.py-2
        v-card-text.pa-2
            vue-markdown.py-0.content-text-ellipsis(v-if="currentType=='TEXT'" :source='String(localContentMessage)')
            div.d-flex.justify-center.align-center(v-else)
                v-img(:src="currentReferenceImage" :aspect-ratio="16/9" error max-width='100' )
            //- p(v-else) {{currentReference.filename}}
            //- div(class='font_arial' v-html='errorMessage')
        //- v-card-actions.px-2.pb-5
        //-     v-row
        //-         v-col.pl-2(cols='12')
        //-             v-btn(v-hide-element='false' :ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed='depressed' dark='dark') {{$t('GENERAL.CLOSE')}}
</template>

<script>
	import Vue from 'vue';
    import VueMarkdown from 'vue-markdown-v2';

	export default Vue.extend({
        props: {
            contentMessage: {
                type: String,
                required: true,
            },
            sourceType: {
                type: String,
                required: true,
            },
            currentReference:{
                type: Object,
                required: true,
            },
            dialogKey:{
                type: Number,
                required: true,
            }
        },
        created() {
            console.log('**************************************')
            console.log('11111',this.currentReference)
            this.currentType = this.currentReference.display_type
            let data = JSON.parse(JSON.stringify(this.currentReference));
            this.localContentMessage = this.currentReference.cut_content
            this.currentReferenceImage = data.doc_image_urls[0];
        },
        components: {
            VueMarkdown
        },
        data() {
            return {
                localContentMessage: '',
                currentReferenceImage:'',
                currentType:'IMAGE'
            };
        },
        methods: {

		},
        watch: {
            'currentReference.context_id'(newVal, oldVal){
                console.log(oldVal, newVal);
                this.localContentMessage = this.contentMessage;
            }
        }
	});
</script>