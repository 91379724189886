<!DOCTYPE html>
<template>
  <v-card
      :class="{'text-box-container':true, 'has-text': message.length > 0 || ishover}" class="text-box-container"
      @mouseleave="ishover = false" @mouseover="ishover = true">
    <!--     上方輸入框 -->
    <v-text-field
        v-model="message"
        class="input-field-upper  custom-height h5-en-regular ma-0 pa-0 d-flex align-end  custom-text-field v-input__control"
        hide-details
        persistent-placeholder
        placeholder="New Thread"
        underlined
        @keydown.enter='sendMessage'
    ></v-text-field>

    <!-- 下方選單 + 送出按鈕 -->
    <v-container class="d-flex" style="max-width: 752px ; max-height: 40px; padding: 2px 8px;">
      <v-menu offset-y persistent transition="slide-y-transition d-flex align-center">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="kw-box d-flex justify-start align-center custom-btn" color="transparent" icon v-bind="attrs"
                 v-on="on">
            <v-avatar class="kw-button avatar-effect" icon size="24">
              <v-img contain height="21.5px" src="@/assets/images/knowledge_select.svg" width="21px"></v-img>
            </v-avatar>
            <v-img class="kw-button-tri" contain height="" src="@/assets/images/Triangle_Down.svg"
                   style="height: 5px; width: 10px;"></v-img>
          </v-btn>

        </template>
        <template>
          <v-list dense class = "custom-list">
            <v-list-item v-for="(item) in knowledgeList" :key="item.id" class="v-list-box"
                         @click.stop="toggleSelection(item)">
              <v-icon v-if="!select_knowledge_id.includes(item.id)"
                      class="checkbox v-icon notranslate far fa-square theme--light" size="16"></v-icon>
              <v-icon v-else
                      class="checkbox v-icon notranslate fas fa-check-square theme--light primary--text"
                      size="16"></v-icon>

              <v-avatar :color= "item.color" class="kw_icon" size="20">
                <span class="p3-TC kw_icon_word">{{ getFirstChac(item.name) }}</span>
              </v-avatar>

              <v-list-item-content style="height: 22px; width: 140px;">
                <v-list-item-title class="p1-en-regular" v-text="item.name" style="color: #666666"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
      <!-- 送出按鈕 -->
      <v-spacer></v-spacer>
      <v-btn :disabled="!hasText" class="send-button  d-flex justify-end align-center" icon @click="sendMessage">
        <v-img :src="sendButtonIcon" contain height="32" width="32"></v-img>
      </v-btn>
    </v-container>
  </v-card>
</template>

<script>
import KnowledgeLibraryService from "@/services/KnowledgeLibraryService";
export default {
  data() {
    return {
      menuOpen: false, // 手動控制 v-menu 的開關
      message: "",
      sendIconA: require("@/assets/images/send.svg"), // A 圖片 (有文字時)
      sendIconB: require("@/assets/images/send_no_tex.svg"), // B 圖片 (無文字時)
      select_knowledge_id: [], // 用來儲存選中的類型
      Knowledges_list: [], // 知識庫列表
      select_knowledge: [],
      kw_color: ["#9ACFFF", "#C593EF", "#FF8F34","#F08B8B","#26C0C0","#AA9100","#0EACF6","#AF5BE6","#E18C4F","#ED4242","#1C9797","#806D00"],
      ishover: false,
    };
  },
  props: {
    knowledgeList: {
        type: Array,
        require:true,
    },
  },
  computed: {
    hasText() {
      return this.message.trim().length > 0;
    },
    sendButtonIcon() {
      return this.hasText ? this.sendIconA : this.sendIconB;
    },
  },
  async created() {
    await this.getKnowledgeList();
    this.loading = false;
    await this.default_select_all_knowledge();
  },
  methods: {
    default_select_all_knowledge() {
      this.select_knowledge_id = this.Knowledges_list.map(item => item.id);
      console.log("Selected ID",this.select_knowledge_id);
    },
    sendMessage() {
      if (this.hasText) {
         this.select_knowledge = this.select_knowledge_id.map( id=> {
              const item = this.Knowledges_list.find(item => item.id === id);
              return item ? {id: item.id, name: item.name} : null;
            }).filter(item => item !== null);
        console.log(this.select_knowledge);
        let data = {
          "text": this.message,
          "category": this.select_knowledge
        }

        this.$emit("sendmessage", data);
        this.message = ""; // 清空輸入框
      }
    },
    // 切換選擇
    toggleSelection(item) {
      const index = this.select_knowledge_id.indexOf(item.id);
      if (index === -1) {
        this.select_knowledge_id.push(item.id);
      } else {
        this.select_knowledge_id.splice(index, 1);
      }
    },
    getFirstChac(name) {
      return name.charAt(0).toUpperCase();
    },
    async getKnowledgeList() {
      await KnowledgeLibraryService.list().then((response) => {
        this.Knowledges_list = response.data;
        const temp_list = this.Knowledges_list
            .filter(el => el.active === true &&
                el.linked_bots_and_knowledge_set.some(bot => bot.ai_bot_id.bot_name === "UpGPT Portal Agent")
            )
            .map(el => ({
                id: el.id,
                name: el.name
            }));
        this.Knowledges_list = temp_list;
      })
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/style/portal/typography.scss";
@import "@/style/portal/component.scss";

/* 調整 v-input__control 的高度 */
.custom-btn:active .avatar-effect,
.custom-btn:focus .avatar-effect {
  background-color: rgba(0, 0, 0, 0.2); /* 點擊時的灰色背景 */
  border-radius: 50%; /* 確保背景是圓形的 */
  transition: background-color 0.2s ease-in-out;
}

.v-input__control .v-input__underlined {
  transition: none !important; /* 禁用動畫 */
}

.v-menu__content {
  box-shadow: 0px 0px 8px #9999994D;
}
.custom-list{
  max-height: 216px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-list::-webkit-scrollbar {
  width: 6px; /* 調整滾動條寬度 */
}
.custom-list::-webkit-scrollbar-track {
  background:  #F4F4F4 !important;
}
.custom-list::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153, 0.3) ;
  border-radius: 10px;
}
</style>
