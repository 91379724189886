<template lang="pug">
    v-card.mark-card.portal-drawer-dialog(:height="windowHeight" :max-height="windowHeight" elevation='0' v-if='referenceDeatil && Object.keys(referenceDeatil).length').portaldrawerDialog
        v-card-title.d-flex.justify-between-space.px-0.py-1(style='border-bottom: 1px solid #E8E8E8;')
            v-col.closeBtn
                v-btn.close-line(icon @click='onCloseDialog' tile )
                    v-icon(size='35') mdi-chevron-left
                span {{referenceDeatil.filename}}
            v-col(cols="auto")
                v-btn(icon @click='onCloseDialog')
                    v-icon mdi-close

        v-card-actions.d-flex.justify-center.px-6 
            v-row.d-flex.flex-nowrap.justify-center 
                v-col.px-0(cols='auto')
                    v-btn.portal-btn-black.pl-0( :ripple='false' @click='copyAnswer(referenceDeatil.content)' width='100%' height='40' color="transparent" depressed dark)
                        v-img(v-if='!copied' src="@/assets/images/copy.svg" max-width="15px" width="15px")
                        v-img(v-else src="@/assets/images/copied.svg" max-width="15px" width="15px")
                        span.pl-1(v-if='!copied') Copy
                        span.pl-1(v-else) Copied
                v-col.px-0()
                    v-btn.portal-btn-black.pl-0(v-if='referenceDeatil.cloud_url !== undefined' :ripple='false' @click='onOpen(referenceDeatil.cloud_url)' width='100%' height='40' color="transparent" depressed dark)
                        v-img(src="@/assets/images/open-icon.svg" max-width="18px" width="18px")
                        span.pl-1 Open
                //- v-col.px-0()
                //-     v-btn.portal-btn-black.pl-0(:ripple='false' @click='onCancel' width='100%' height='40' color="transparent" depressed dark)
                //-         v-icon(size='18') icon-remove
                //-         span Remove Reference
        v-card-title.flex-nowrap.py-0.mb-3.d-flex.align-center.ellipsis()
            v-avatar.mark.ml-1( size='20' tile)
                span(style='font-size:10px;') {{referenceDeatil.context_id}}
            span.ellipsis.ml-2.textx-subtitle-2.font-weight-bold(:title='referenceDeatil.title?referenceDeatil.title:referenceDeatil.filename') {{referenceDeatil.title?referenceDeatil.title:referenceDeatil.filename}}
                        
        v-card-text.px-5.answer-line-height.text-scrollbar.source-type-text-content(v-if="referenceDeatil.source_type='TEXT'")
            vue-markdown(:source='String(referenceDeatil.content)')
            v-row.card-text-action(v-if="referenceDeatil.doc_image_urls.length>0")
                v-col.pl-0.pr-2.pt-0.pb-2(v-for="(img,index) in filteredImages(referenceDeatil.doc_image_urls)" :key="index" :cols="index==0 ? 12:6")
                    v-card.related-img-card(elevation='0')
                        v-img(:src="img" :aspect-ratio="16/9" error @click='openWindowDisplayImage(img)' style="cursor :pointer;")
                        v-card-text.ellipsis.related-img-overlay
                            v-chip.related-img-label.mr-1(label) {{referenceDeatil.context_id}}
                            span.text-white(v-if="referenceDeatil.page_num.length>0") Page {{referenceDeatil.page_num.length>0?referenceDeatil.page_num[0]:0}},
                            span.pl-1.text-white(v-if="referenceDeatil.image_num!=''") Image {{referenceDeatil.image_num==''?0:referenceDeatil.image_num}}
                v-col.py-0.d-flex(cols='12' style='margin-bottom:30px;')
                    v-btn.portal-btn-black.px-0(v-if='referenceDeatil.doc_image_urls.length>3' @click='viewLessRelatedImage()' text elevation='0' color="transparent")
                        v-img(src="@/assets/images/related-img-btn.svg" max-width="20px" width="20px")
                        span.ml-1(v-if="showAllImage") {{$t("PORTAL.ViewMore")}}
                            v-icon.ml-1(size='12' color='#444') fa-chevron-up
                        span.ml-1(v-else) {{$t("PORTAL.ViewLess")}} 
                            v-icon.ml-1(size='12') fa-chevron-down
        v-card-text.px-5.text-scrollbar(v-else)
            v-col.pl-0.pr-2.pt-0.pb-2(v-for="(img,index) in referenceDeatil.doc_image_urls" :key="index" :cols="index==0 ? 12:6")
                v-card.related-img-card(elevation='0')
                    v-img(:src="img" :aspect-ratio="16/9" @click='openWindowDisplayImage(img)' style="cursor :pointer;")
                    v-card-text.ellipsis.related-img-overlay
                        //- v-chip.related-img-label.mr-1(label) {{index+1}}
                        span.text-white(:title='img.image_page' v-if="referenceDeatil.page_num.length>0") Page {{referenceDeatil.page_num.length>0?referenceDeatil.page_num[0]:0}},
                        span.text-white.pl-1(:title='img.image_page' v-if="referenceDeatil.image_num!=''") Image {{referenceDeatil.image_num==''?0:referenceDeatil.image_num}}

</template>

<script>
    import Vue from 'vue';
    import VueMarkdown from 'vue-markdown-v2';
    export default Vue.extend({
        props: {
            selectedReference: {
                type: Object,
                required: true,
            },
        },
        components: {
            VueMarkdown,
        },
        async created(){
            
        },
        data() {
            return{
                referenceDeatil:{},
                windowHeight: window.innerHeight,
                copied:false,
                showAllImage: true,
            }
        },
        methods: {
            onCloseDialog(){
                this.$emit("emitPortalDrawer",false)
            },
            onCancel(){},
            copyAnswer(text){
                // text content field
                let contentEl = document.querySelector('.source-type-text-content');
                contentEl.setAttribute('contenteditable', 'true');
                // contentEl.focus();
                let hasTable = contentEl.querySelectorAll('table').length > 0;

                // ---if have table tag use execCommand to copy
                if(hasTable){
                    let range = document.createRange();
                    range.selectNodeContents(contentEl);
                    let selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand('copy');
                    selection.removeAllRanges();
                }else{
                    text = text.replaceAll(/\[([^\]]+)\]/g,'$1').replace(/\*\*(.*?)\*\*/g, '$1')
                    const clipboardData =
                    event.clipboardData ||
                    window.clipboardData ||
                    event.originalEvent?.clipboardData ||
                    navigator.clipboard;
                    clipboardData.writeText(text);
                }
                if(this.copied == false){
                    this.copied = true;
                    setTimeout(()=>{
                        this.copied = false;
                    },1000)
                }
            },
            onOpen(url){
                window.open(url, '_blank');
            },
            openWindowDisplayImage(img){
                console.log('imgdata',img)
                let queryString = {
                    doc_image_url:img,
                }
                let routeUrl = this.$router.resolve(
                    { 
                        path: '/image_url',
                        query:queryString
                    }
                )
                window.open(routeUrl.href, '_blank')
            },
            filteredImages(data){
                return this.showAllImage ? data.slice(0,3) : data
            },
            viewLessRelatedImage(){
                this.showAllImage = !this.showAllImage
            },
        },
        computed: {

        },
        watch: {
            selectedReference:{
                handler(newVal) {this.referenceDeatil=newVal;
                    console.log('d',this.referenceDeatil)
                }
            },
            immediate: true,
            // deep: true,
        }

    })
</script>
<style scoped>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle{
    color: #333 !important;
}
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close), .v-navigation-drawer--temporary:not(.v-navigation-drawer--close){
    box-shadow: -8px 0px 8px 0px rgba(153, 153, 153, 0.1) !important;
}
</style>